define("discourse/plugins/discourse-shared-edits/discourse/initializers/extend-composer-service", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-shared-edits/discourse/lib/shared-edits"], function (_exports, _pluginApi, _sharedEdits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHARED_EDIT_ACTION = "sharedEdit";
  var _default = _exports.default = {
    name: "discourse-shared-edits-composer-service",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", api => {
        api.modifyClass("service:composer", Superclass => class extends Superclass {
          init() {
            super.init(...arguments);
            this.addObserver("model.reply", this, this._handleSharedEdit);
          }
          willDestroy() {
            super.willDestroy(...arguments);
            this.removeObserver("model.reply", this, this._handleSharedEdit);
          }
          _handleSharedEdit() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.performSharedEdit)(this.model);
            }
          }
          async open(opts) {
            await super.open(...arguments);
            if (opts.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.setupSharedEdit)(this.model);
            }
          }
          collapse() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return super.collapse(...arguments);
          }
          close() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.teardownSharedEdit)(this.model);
            }
            return super.close(...arguments);
          }
          save() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return this.close();
            }
            return super.save(...arguments);
          }
          _saveDraft() {
            if (this.model?.action === SHARED_EDIT_ACTION) {
              return;
            }
            return super._saveDraft(...arguments);
          }
        });
      });
    }
  };
});